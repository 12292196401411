<template>
  <div class="coinater">
    <div class="fdleft">
      <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ project.name }}</div>
        </div>
      </div>
      <div class="fdlcont">
        <div class="fdltitle">能效测评汇总表</div>
        <div class="fdltxt">
          <div class="tableform">
            <el-form ref="form" :model="project" label-width="180px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目名称：">
                        {{ project.name }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="建筑面积（m²）/层数：">
                        {{ project.building_area }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="建设单位：">
                        {{ project.build_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="施工单位：">
                        {{ project.work_build }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目地址：">
                        {{ project.address }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="气候区域：">
                        <span v-if="project.weather == 1">夏热冬冷</span>
                        <span v-if="project.weather == 2">寒冷</span>
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="设计单位：">
                        {{ project.design_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="监理单位：">
                        {{ project.manger_company }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-form ref="formtable" :model="formtable" label-width="180px">
            <div id="engerytable" class="engerytable">

              <table border="1" cellspacing="0" width="100%">
                <tbody>
                  <tr>
                    <th colspan="8" scope="col" style="height:40px">&nbsp;测评内容</th>
                    <th scope="col">&nbsp;测评方法</th>
                    <th scope="col">&nbsp;测评结果</th>
                  </tr>
                  <tr>
                    <th rowspan="3" scope="row">基础类&nbsp;</th>
                    <td colspan="3">&nbsp;供暖耗能（kW·h/m²）</td>
                    <td colspan="2"><input v-model="formtable.heatEnergy" disabled type="text"></td>
                    <td colspan="2" rowspan="3">&nbsp; 相对节能率（%）</td>
                    <td><input v-model="formtable.evaluationWay_heatEnergy" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_heatEnergy" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;空调能耗（kW·h/m²）</td>
                    <td colspan="2"><input v-model="formtable.airEnergy" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_airEnergy" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_airEnergy" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">单位建筑面积全年能耗量（kWh/m² )</td>
                    <td colspan="2"><input v-model="formtable.buildEnergy" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_buildEnergy" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_buildEnergy" type="text"></td>
                  </tr>
                  <tr>
                    <th :rowspan="29 + jizu.length + jizu2.length + jizu3.length + jizu4.length" scope="row">&nbsp;规定项</th>
                    <td rowspan="5">&nbsp;围护结构</td>
                    <td colspan="2">&nbsp;外窗、透明幕墙气密性</td>
                    <td colspan="4"><input v-model="formtable.wallGas" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_wallGas" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_wallGas" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2">热桥部位&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.hotPart" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_hotPart" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_hotPart" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">门窗保温&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.doorHeat" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_doorHeat" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_doorHeat" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">透明幕墙可开启面积</td>
                    <td colspan="4"><input v-model="formtable.wallOpenArea" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_wallOpenArea" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_wallOpenArea" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">外遮阳&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.outShade" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_outShade" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_outShade" type="text"></td>
                  </tr>
                  <tr>
                    <td :rowspan="19 + jizu.length + jizu2.length + jizu3.length + jizu4.length" scope="row">&nbsp;冷 热 源 及 空 调 系
                      统</td>
                    <td colspan="2" scope="row">&nbsp; 空调冷源</td>
                    <td colspan="4"><input v-model="formtable.coldSource" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_coldSource" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_coldSource" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">供暖热源&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.hotSource" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_hotSource" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_hotSource" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="2" scope="row">&nbsp; 锅炉</td>
                    <td colspan="2">类型&nbsp;</td>
                    <td colspan="2">&nbsp; 额定热效率（%）</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th colspan="2" scope="row"><input v-model="formtable.boilerType" type="text"></th>
                    <td colspan="2"><input v-model="formtable.rateEffic" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_rateEffic" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_rateEffic" type="text"></td>
                  </tr>
                  <tr>
                    <td :rowspan="jizu.length + 1" colspan="2" scope="row">冷水（热泵）机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(1)">
                        增加 冷水（热泵）机组
                      </el-button> -->
                      </div>
                    </td>
                    <td>&nbsp;类型</td>
                    <td>&nbsp; 单机额定制冷量（kW）</td>
                    <td>&nbsp;台数</td>
                    <td>&nbsp;性能系数（COP）</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for="item in jizu" :key="item">
                    <th scope="row"><input v-model="item.unitType" type="text"></th>
                    <td><input v-model="item.unitMeasure" type="text"></td>
                    <td><input v-model="item.unitNum" type="text"></td>
                    <td><input v-model="item.unitErr" type="text"></td>
                    <td><input v-model="item.evaluationWay" type="text"></td>
                    <td><input v-model="item.evaInstitu" type="text"></td>
                  </tr>
                  <tr>
                    <td :rowspan="jizu2.length + 1" colspan="2" scope="row">单元式空气调节机、风管送风式和屋顶调节机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(2)">
                        单元式空气调节机、风管送风式和屋顶调节机组
                      </el-button> -->
                      </div>
                    </td>
                    <td>&nbsp;类型</td>
                    <td>&nbsp; 单机额定制冷量（kW）</td>
                    <td>&nbsp; 台数</td>
                    <td>&nbsp;能效比（EER）</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for="item in jizu2" :key="item">
                    <th scope="row"><input v-model="item.unitType" type="text"></th>
                    <td><input v-model="item.unitMeasure" type="text"></td>
                    <td><input v-model="item.unitNum" type="text"></td>
                    <td><input v-model="item.unitErr" type="text"></td>
                    <td><input v-model="item.evaluationWay" type="text"></td>
                    <td><input v-model="item.evaInstitu" type="text"></td>
                  </tr>
                  <!-- <tr>
            <th scope="col" colspan="8" style="height:40px">测评内容&nbsp;</th>
            <th scope="col">测评方法&nbsp;</th>
            <th scope="col">&nbsp;测评结果</th>

          </tr> -->
                  <tr>
                    <!-- <th scope="row" :rowspan="18+jizu3.length+jizu4.length">&nbsp;规定项</th> -->
                    <!-- <td  :rowspan="13+jizu3.length+jizu4.length">冷热源及空调系统</td> -->
                    <td :rowspan="jizu3.length + 1" colspan="2">多联式空调（热泵）机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(3)">
                        增加 多联式空调（热泵）机组
                      </el-button> -->
                      </div>
                    </td>
                    <td>&nbsp;类型</td>
                    <td>&nbsp;单机额定制冷量(kW)</td>
                    <td>&nbsp;台数</td>
                    <td>&nbsp;综合性能系数(IPLV)</td>
                    <td></td>
                    <td></td>

                  </tr>
                  <tr v-for="item in jizu3" :key="item">
                    <th scope="row"><input v-model="item.unitType" type="text"></th>
                    <td><input v-model="item.unitMeasure" type="text"></td>
                    <td><input v-model="item.unitNum" type="text"></td>
                    <td><input v-model="item.unitErr" type="text"></td>
                    <td><input v-model="item.evaluationWay" type="text"></td>
                    <td><input v-model="item.evaInstitu" type="text"></td>
                  </tr>
                  <tr>
                    <td :rowspan="jizu4.length + 1" colspan="2" scope="row">溴化锂吸收式机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(4)">
                        增加溴化锂吸收式机组
                      </el-button> -->
                      </div>
                    </td>
                    <td>&nbsp;类型</td>
                    <td>&nbsp; 单机额定制冷量(kW)</td>
                    <td>&nbsp; 台数</td>
                    <td>&nbsp; 综合性能系数(IPLV)</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr v-for="item in jizu4" :key="item">
                    <th scope="row"><input v-model="item.unitType" type="text"></th>
                    <td><input v-model="item.unitMeasure" type="text"></td>
                    <td><input v-model="item.unitNum" type="text"></td>
                    <td><input v-model="item.unitErr" type="text"></td>
                    <td><input v-model="item.evaluationWay" type="text"></td>
                    <td><input v-model="item.evaInstitu" type="text"></td>
                  </tr>

                  <tr>
                    <td colspan="2" scope="row">室内设计计算温度</td>
                    <td colspan="4"><input v-model="formtable.indoorTem" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_indoorTem" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_indoorTem" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">设计新风量&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.windNum" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_windNum" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_windNum" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">集中供暖系统热水循环水泵的耗电输热比</td>
                    <td colspan="4"><input v-model="formtable.focusRatio" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_focusRatio" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_focusRatio" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">风机单位风量耗功率</td>
                    <td colspan="4"><input v-model="formtable.windPower" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_windPower" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_windPower" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">空调水系统耗电输热比</td>
                    <td colspan="4"><input v-model="formtable.airRatio" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_airRatio" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_airRatio" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">室温调节设施</td>
                    <td colspan="4"><input v-model="formtable.indoorFacil" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_indoorFacil" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_indoorFacil" type="text"></td>
                  </tr>
                  <tr>
                    <td rowspan="3" scope="row">计量装置</td>
                    <td>&nbsp;集中供暖系统</td>
                    <td colspan="4"><input v-model="formtable.facusSystem" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_facusSystem" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_facusSystem" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">区域冷/热源&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.regionSource" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_regionSource" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_regionSource" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">制冷站空调系统&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.coldSystem" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_coldSystem" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_coldSystem" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">水力平衡</td>
                    <td colspan="4"><input v-model="formtable.waterBalence" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_waterBalence" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_waterBalence" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">监测和控制系统</td>
                    <td colspan="4"><input v-model="formtable.detectSystem" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_detectSystem" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_detectSystem" type="text"></td>
                  </tr>
                  <tr>
                    <td rowspan="3" scope="row">照明系统</td>
                    <td colspan="2">&nbsp;照明功率密度</td>
                    <td colspan="4"><input v-model="formtable.lightDensity" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_lightDensity" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_lightDensity" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">照度的时序自动控制装置</td>
                    <td colspan="4"><input v-model="formtable.lightAuto" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_lightAuto" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_lightAuto" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">&nbsp;降低照度控制措施</td>
                    <td colspan="4"><input v-model="formtable.lowerlight" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_lowerlight" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_lowerlight" type="text"></td>
                  </tr>
                  <tr>
                    <td rowspan="2" scope="row">生活热水系统</td>
                    <td colspan="2">&nbsp;热泵性能</td>
                    <td colspan="4"><input v-model="formtable.heatPump" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_heatPump" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_heatPump" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">热水监控&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.hotMonitor" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_hotMonitor" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_hotMonitor" type="text"></td>
                  </tr>
                  <!-- <tr>
                <th scope="col" colspan="8" style="height:40px">&nbsp;测评内容</th>
                <th scope="col">&nbsp;测评方法</th>
                <th scope="col">&nbsp;测评结果</th>
              </tr> -->
                  <tr>
                    <th rowspan="19" scope="row">选择项&nbsp;</th>
                    <td rowspan="5">&nbsp;可升再能源</td>
                    <td>&nbsp;太阳能热水系统</td>
                    <td>集热效率&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.gatherHot" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_gatherHot" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_gatherHot" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">可再生能源发电系统&nbsp;</td>
                    <td>&nbsp;比例</td>
                    <td colspan="4"><input v-model="formtable.renewRatio" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_renewRatio" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_renewRatio" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">太阳能光伏系统&nbsp;</td>
                    <td>&nbsp;光电转换效率</td>
                    <td colspan="4"><input v-model="formtable.lightEffic" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_lightEffic" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_lightEffic" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">&nbsp;地源热泵系统</td>
                    <td>&nbsp;比例</td>
                    <td colspan="4"><input v-model="formtable.earthRatio" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_earthRatio" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_earthRatio" type="text"></td>
                  </tr>
                  <tr>
                    <td scope="row">空气源热泵&nbsp;</td>
                    <td>&nbsp;比例</td>
                    <td colspan="4"><input v-model="formtable.airPump" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_airPump" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_airPump" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;自然通风</td>
                    <td colspan="4"><input v-model="formtable.natureWind" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_natureWind" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_natureWind" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;自然采光</td>
                    <td colspan="4"><input v-model="formtable.natureLight" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_natureLight" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_natureLight" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;遮阳措施</td>
                    <td colspan="4"><input v-model="formtable.lightMeasure" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_lightMeasure" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_lightMeasure" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;能量回放</td>
                    <td colspan="4"><input v-model="formtable.powerBack" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_powerBack" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_powerBack" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;蓄冷蓄热技术</td>
                    <td colspan="4"><input v-model="formtable.saveSkill" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_saveSkill" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_saveSkill" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">冷凝热利用&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.coldHotUse" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_coldHotUse" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_coldHotUse" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;全新风/可变新风比</td>
                    <td colspan="4"><input v-model="formtable.newWind" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_newWind" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_newWind" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;变流量/变风量</td>
                    <td colspan="4"><input v-model="formtable.changeWind" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_changeWind" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_changeWind" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">供回水温差&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.changeTem" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_changeTem" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_changeTem" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;楼宇自控</td>
                    <td colspan="4"><input v-model="formtable.buildControl" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_buildControl" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_buildControl" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;计量统计+节能控制措施</td>
                    <td colspan="4"><input v-model="formtable.energyControl" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_energyControl" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_energyControl" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">冷热源设备能效等级&nbsp;</td>
                    <td colspan="4"><input v-model="formtable.energyGrade" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_energyGrade" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_energyGrade" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;风扇调风</td>
                    <td colspan="4"><input v-model="formtable.airCond" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_airCond" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_airCond" type="text"></td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row">&nbsp;其他新型节能措施</td>
                    <td colspan="4"><input v-model="formtable.otherMeasure" type="text"></td>
                    <td><input v-model="formtable.evaluationWay_otherMeasure" type="text"></td>
                    <td><input v-model="formtable.evaInstitu_otherMeasure" type="text"></td>

                  </tr>
                  <tr>
                    <td colspan="10" scope="row">
                      <div class="minyong">
                        <p>1、民用建筑能效测评结论：</p>
                        <p>（1）经软件模拟该项目基础相对节能率为<input v-model="formtable.relateEnergy" type="text">,(设计标准为
                          <span class="minyong_select">
                            <span v-if="formtable.designStand == 1">《公共建筑节能设计标准》GB 50189-2015</span>
                            <span v-if="formtable.designStand == 2">《公共建筑节能设计标准》DGJ32J96-2010</span>
                          </span>
                          <span>
                            );
                          </span>
                        </p>
                        <p>（2）经测评，该项目规定项<input v-model="formtable.strip" type="text">条参评，均满足《民用建筑能效测评标识标准》DB32/T
                          3964-2020规定要求；
                        </p>
                        <p>（3）经测评，该项目选择项<input v-model="formtable.selectStrip" type="text">条参评，加分<input
                            v-model="formtable.grades" type="text">分；
                        </p>
                        <p>（4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T 3964-2020标准要求，建筑节能率为<input
                            v-model="formtable.buildEnergyRatio" type="text">，测评合格。
                        </p>
                        <p>2、民用建筑能效标识建议：</p>
                        <p>依据民用建筑能效测评结论，建议该建筑能效标识为 {{ project.stars }}星。</p>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <th colspan="4" scope="row">测评人员&nbsp;</th>
                    <th colspan="4">&nbsp;审核人员</th>
                    <th colspan="4">&nbsp;批准人员</th>
                  </tr>
                  <tr class="table_select">
                    <th colspan="4" scope="row">
                      <input v-model="formtable.evaluatUser" />
                    </th>
                    <td colspan="4">
                      <input v-model="formtable.auditUser" />
                    </td>
                    <td colspan="4">
                      <input v-model="formtable.approveUser" />
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input v-model="idea" placeholder="请输入内容" style="height:513px;    border: 1px solid #DCDFE6;"
            type="textarea">
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <el-button type="primary" @click="pre_page">上一页</el-button>
          <el-button type="primary" @click="check">保存</el-button>
          <el-button type="primary" @click="next_page">下一页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkSave, projectTableInfo } from "../../../../api/project";

export default {
  data() {
    return {
      idea: '',
      pass: '',
      project_id: '',
      formtable: {},
      project: {},
      dialogVisible: false,
      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],

      staffList: [],
      staffList2: [],
      staffList3: [],


      fileList: [],
    }
  },
  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getInfo();

  },
  methods: {
    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token
      };
      let that = this;
      checkSave(params).then(res => {
        console.log(res);
        that.$router.push({
          path: './formDetail?project_id=' + this.project_id
        })
      })

    },
    next_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token
      };
      let that = this;
      checkSave(params).then(res => {
        console.log(res);
        that.$router.push({
          path: './reportTable?project_id=' + this.project_id
        })
      })
    },
    fliterStaff() {

    },
    getStaffList() {

    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token
      };
      let that = this;
      checkSave(params).then(res => {
        console.log(res);
        that.$message.success('保存成功');
        // that.goBack();
      })
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then(res => {
        console.log(res);
        that.project = res.data;
        let checks = res.data.checks;
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];


        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2)
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.$nextTick(function () {
          let table = document.getElementById('engerytable');
          let inputs = table.getElementsByTagName('input');
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }
        })
      })
    },
    goBack() {
      this.$router.push({
        path: './stepInformation?id=' + this.project_id
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea>textarea {
  height: 513px;
}

/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;

    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 30px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 34px;
      }

      .fdradio {
        margin: 20px 0;
        text-align: center;
      }

      .fdrbtn {
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}

.engerytable input {
  background-color: white;
}
</style>
